@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/reset.scss';
@import '../../../assets/styles/global.scss';

.sfactory-workshop-container {
    height: 880px;
    width: 100%;
}

.sfactory-line-container {
    width: 325px;

    p {
        padding-top: 10px;
        padding-left: 20px;
        color: #fff;
        font-size: 25px;

        span {
            font-size: 40px;
            font-weight: bold;
        }
    }
}



.footer {
    width: 100%;
    height: 100px;
}