@import '../../assets/styles/variables.scss';
@import '../../assets/styles/reset.scss';
@import '../../assets/styles/global.scss';

.customCard-container {
  padding: 10px 5px;
  box-sizing: border-box;
  // background-color: brown;

  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
}
