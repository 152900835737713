@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/reset.scss';
@import '../../../assets/styles/global.scss';

.production-line-entry {
    width     : 190px;
    height    : 320px;
  }
  .line-child-title {
    width: 150px;
    font-size: 35px;
    color: #fff;
    margin-left: 20px;
    margin-top: 10px;

  }

  .oven-temperature-list {
    margin-top: 30px;
    height: 380px;
    width: 580px;
    display: flex;
    justify-items: flex-start;
    flex-wrap: wrap;
    padding-left: 20px;
    .oven-temperature-item {
        height: 100px;
        width: 120px;
        margin-right: 20px;
        background: #eeeeee3e;
        padding-top: 15px;
        padding-left: 10px;
        
    }
  }